export const environment = {
  SUPABASE_URL: `https://okryxlfewrzzgspgvedt.supabase.co`,
  ANON_KEY: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9rcnl4bGZld3J6emdzcGd2ZWR0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTE1MDI4NjgsImV4cCI6MjAwNzA3ODg2OH0.oyNGdCdlQjEOss8SgCJvfMjgt2Aj9WDyxzhlFmmyjO0`,
};

export const TENANT_ID = "e099a0aa-010b-4a1b-ae84-82219f4aa753";
export const CHAPORT_ID = "65c52f90119e5b03160ef70c";
export const SHOP_TENANT_ID = "e099a0aa-010b-4a1b-ae84-82219f4aa753";
export const GIFT_CARDS_TENANT_ID = "75d97a0f-de8b-4265-8a2e-34cec08fd952";
export const RECHARGES_TENANT_ID = "9f4ba0e2-09bb-4d6e-8f89-d7e75e02b70e";

export const SENTRY_ENV = "production";

export const push_uid_name = "DARA";

export const public_push_key =
  "BPzH1SbY4LvZGB9SisLUCYAyk2W8TrCgrJZXQeq-IOIY0nxL8NEWqmj3vO6Q82MtZjdlitzSQxwpWxbGzr0zoXk";
